<template>
  <div>
  
    <b-modal
      id="importCCVendorModal"
      ref="importCCVendorModal"
      title="Select Vendor Services"
      title-tag="h2"
      centered
      size="lg"
      hide-footer
    >
      <rotate-square2 id="loading-gif" v-show="loadingImport"></rotate-square2>
      <b-row style="max-height: 500px; overflow: scroll">
        <div
          class="col-md-6"
          v-for="service in vendorServices"
          :key="service.vasid"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="service"
              v-model="vendorSelectedServices"
            />
            <label class="form-check-label"
              ><strong>{{ service.name }}</strong></label
            >
          </div>
        </div>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="6">
          <b-button
            variant="success"
            :disabled="loadingImport"
            @click="sendChain(true)"
            class=""
            type="submit"
            block
            >Assign Selected <i class="fa fa-file-import"></i
          ></b-button>
        </b-col>
        <b-col lg="6">
          <b-button
            variant="success"
            :disabled="loadingImport"
            @click="sendChain()"
            class=""
            type="submit"
            block
            >Assign All <i class="fa fa-file-import"></i
          ></b-button>
        </b-col>
      </b-row>
    </b-modal>
    
    <v-server-table
      v-if="!is_resource"
      class="statusTable import-table"
      :columns="columnsServices"
      :options="optionsServices"
      id="dataTable"
    >
      <div slot="status" slot-scope="props">
        <span v-if="props.row.status === 0">
          <button class="btn btn-sm btn-success w-100">Active</button>
        </span>
        <span v-if="props.row.status !== 0">
          <button class="btn btn-sm btn-danger w-100">Inactive</button>
        </span>
      </div>
      <div slot="uom1" slot-scope="props">
        ${{ props.row.pu1 }}/{{ props.row.uom1 }}
      </div>
      <div slot="uom2" slot-scope="props">
        <span v-if="props.row.pu2"
          >${{ props.row.pu2 }}/{{ props.row.uom2 }}</span
        >
      </div>
      <div slot="uom3" slot-scope="props">
        <span v-if="props.row.pu3"
          >${{ props.row.pu3 }}/{{ props.row.uom3 }}</span
        >
      </div>

      <div slot="assign" slot-scope="props">
        <div class="row">
          <div class="col-md-10">
            <select
              class="select-account form-control"
              name="vendorid"
              v-model="vendor[props.index]"
            >
        
              <option value="0" disabled selected>Select Vendor</option>
              <option
                for
                v-for="vendor in wtsVendorList"
                v-bind:value="vendor.vid"
              >
                {{ vendor.fname }} {{ vendor.lname }} ({{
                  vendor.company_name
                }})
              </option>
            </select>
          </div>
          <div class="col-md-2 check-buttons" v-if="vendor[props.index]">
            <b-button
              v-b-tooltip.hover
              title="Import To Table"
              v-model="button[props.index]"
              variant="success"
              class="mr-2 btn link"
              @click="showImportCCVendorModal(props.row, vendor[props.index])"
              ><i class="fa fa-plus"></i>
            
            </b-button>
          </div>
        </div>
      </div>
    </v-server-table>
    <v-server-table
      v-else
      class="statusTable import-table"
      :columns="columnsServices"
      :options="optionsServicesResources"
      id="dataTable"
    >
      <div slot="status" slot-scope="props">
        <span v-if="props.row.status === 0">
          <button class="btn btn-sm btn-success w-100">Active</button>
        </span>
        <span v-if="props.row.status !== 0">
          <button class="btn btn-sm btn-danger w-100">Inactive</button>
        </span>
      </div>
      <div slot="uom1" slot-scope="props">
        ${{ props.row.pu1 }}/{{ props.row.uom1 }}
      </div>
      <div slot="uom2" slot-scope="props">
        <span v-if="props.row.pu2"
          >${{ props.row.pu2 }}/{{ props.row.uom2 }}</span
        >
      </div>
      <div slot="uom3" slot-scope="props">
        <span v-if="props.row.pu3"
          >${{ props.row.pu3 }}/{{ props.row.uom3 }}</span
        >
      </div>

      <div slot="assign" slot-scope="props">
        <div class="row">
          <div class="col-md-10">
            <select
              class="select-account form-control"
              name="vendorid"
              v-model="vendor[props.index]"
            >
              <option value="0" disabled selected>Select Vendor</option>
              <option
                for
                v-for="vendor in wtsVendorList"
                v-bind:value="vendor.vid"
              >
                {{ vendor.fname }} {{ vendor.lname }} ({{
                  vendor.company_name
                }})
              </option>
            </select>
          </div>
          <div class="col-md-2 check-buttons" v-if="vendor[props.index]">
            <b-button
              v-b-tooltip.hover
              title="Import To Table"
              v-model="button[props.index]"
              variant="success"
              class="mr-2 btn link"
              @click="showImportCCVendorModal(props.row, vendor[props.index])"
              ><i class="fa fa-plus"></i>
            </b-button>
          </div>
        </div>
      </div>
    </v-server-table>
  </div>
</template>

<script>
import { ServerTable, ClientTable } from "vue-tables-2";
import VueCsvImport from "vue-csv-import";
import Papa from "papaparse";
import _ from "lodash";
import Blob from "blob";
import FileSaver from "file-saver";
import axios from "axios";
import { RotateSquare2 } from "vue-loading-spinner";
import Vue from "vue";
Vue.use(ServerTable);
window.axios = require("axios");

export default {
  name: "WtsChainImport",
  props: ["is_resource", "pwcaid", "name", "update", "existing", "companyid"],
  components: {
    ClientTable,
    VueCsvImport,
    RotateSquare2,
  },
  data: function () {
    return {
      parseCsv: [],
      clicked: [],
      type: "",
      servicename: "",
      serviceid: "",
      vendor: [],
      button: [],
      uom1: "",
      uom2: "",
      uom3: "",
      pu1: "",
      pu2: "",
      pu3: "",
      status: "",
      error: "",
      wtsVendorList: [],
      currency: "CAD",
      doc: [],
      csv: "",
      //Services Data Table
      columnsServices: [
        "costcode1",
        "costcode2",
        "costcode3",
        "optionaldescription",
        "assign",
      ],
      optionsServices: {
        perPage: 20, 
        pagination: {
            edge: false,
            dropdown: false,
            chunk: 10
        },
        headings: {
          costcode1: "Cost Code 1",
          costcode2: "Cost Code 2",
          costcode3: "Cost Code 3",
          optionaldescription: "Optional Description",
          assign: "Assign To Vendor Services",
        },
        sortable: ["costcode1", "costcode2", "costcode3"],
        filterable: true,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
    params:{ 
     Pid: this.$route.params.id
     },
        requestKeys: {
          query: "query",
          limit: "limit",
          orderBy: "orderBy",
          ascending: "ascending",
          page: "page",
          byColumn: "byColumn",
        },
        requestFunction(data) {
          return this.$axios
            .get(`/wts/export/cost_code_chain/getCostCode`, {
              params: data,
            })
            .catch((error) => {});
        },
        responseAdapter(response) {
          return {
            data: response.data.result.rows,
            count: response.data.result.count,
          };
        },
      },
      optionsServicesResources: {
        perPage: 20, 
        pagination: {
            edge: false,
            dropdown: false,
            chunk: 10
        },
        headings: {
          costcode1: "Major",
          costcode2: "Minor",
          costcode3: "Description",
          optionaldescription: "Optional Description",
          assign: "Assign To Vendor Services",
        },
        sortable: ["costcode1", "costcode2", "costcode3"],
        filterable: true,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        requestKeys: {
          query: "query",
          limit: "limit",
          orderBy: "orderBy",
          ascending: "ascending",
          page: "page",
          byColumn: "byColumn",
        },
      params:{ 
     Pid: this.$route.params.id
     },
        requestFunction(data) {
          return this.$axios
            .get(`/wts/export/cost_code_chain/getCostCode`, {
              params: data,
            })
            .catch((error) => {});
        },
        responseAdapter(response) {
          return {
            data: response.data.result.rows,
            count: response.data.result.count,
          };
        },
      },
      services: [],
      theme: "bootstrap4",
      template: "default",
      useVuex: true,
      wtsVendorServiceData: [],
      successServices: [],
      serviceErrors: [],
      vendorServices: [],
      vendorSelectedServices: [],
      loadingImport: false,
    };
  },
  watch: {
    pwcaid: function (newVal) {
      if (newVal) {
        this.loadData();
      }
    },
    update: function (newVal) {
      if (newVal) {
        // this.doc = []
        this.vendor = [];
      }
    },
  },
  methods: {
  
    showImportCCVendorModal(row, id) {
      this.row = row;
      this.$axios
        .get("/wts/get/vas/" + this.$route.params.id)
        .then((response) => {
          console.log(response);
          var temp = response.data.result.filter((x) => x.vid == id);
          this.vendorServices = temp;
          this.vendorSelectedServices = [];
          this.$refs.importCCVendorModal.show();
        });
    },

    async sendChain(type) {
      let serviceids = [];
      if (type) {
        serviceids = this.vendorSelectedServices;

      } else {
        serviceids = this.vendorServices;
      }
      let send = JSON.stringify({
        serviceids: serviceids,
        projectid: this.$route.params.id,
        pwcaid: this.pwcaid,
        costcode1: this.row.costcode1,
        costcode2: this.row.costcode2,
        costcode3: this.row.costcode3,
        companyid: this.companyid,
      
      });


      if (serviceids.length) {
   
      this.$http
          .post("/wts/create/costcode_and_assign_service", send)
          .then((response) => {
            if (response.status === 200) {
              this.$toasted.show("Successful Import.", {
                type: "success",
                duration: "3000",
              });
              this.$refs.importCCVendorModal.hide();
              console.log(response);
            }
          })
          .catch((error) => {
            this.$toasted.show("Error.", { type: "error", duration: "3000" });
          });
      } else {
        this.$toasted.show("Please select atleast one service", {
          type: "error",
          duration: "3000",
        });
      }
    },

    getCC1s(code) {
      var cc1Data = [];
      var cc1 = 0;
      var match = [];

      return this.$axios
        .get("/wts/get/cc1/" + this.$route.params.id + "/" + this.pwcaid)
        .then((response) => {
          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length === 0) {
            return this.createCC1(code).then((data) => {
              cc1 = data.id;
              return cc1;
            });
          }
          if (match.length > 0) {
            cc1 = match[0].cconeid;
            return cc1;
          }
        });
    },
    createCC1(code) {
      let data = JSON.stringify({
        pwcaid: this.pwcaid,
        projectid: this.$route.params.id,
        code: code,
      });

      return this.$axios.post("/wts/create/cc1/", data).then((response) => {
        return response.data;
      });
    },
    getCC2s(cc1, code) {
      var cc2Data = [];
      var cc2 = 0;
      var match = [];

      return this.$axios
        .get("/wts/get/cc2/" + this.$route.params.id + "/afe/ccone/" + cc1)
        .then((response) => {
          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length === 0) {
            return this.createCC2(cc1, code).then((data) => {
              cc2 = data.id;
              return cc2;
            });
          }
          if (match.length > 0) {
            cc2 = match[0].cctwoid;
            return cc2;
          }
        });
    },
    createCC2(cconeid, code) {
      let data = JSON.stringify({
        cconeid: cconeid,
        projectid: this.$route.params.id,
        code: code,
      });

      return this.$axios.post("/wts/create/cc2/", data).then((response) => {
        return response.data;
      });
    },
    getCC3s(cc2, code) {
      var cc3Data = [];
      var cc3 = 0;
      var match = [];
      return this.$axios
        .get(
          "/wts/get/cc3/" +
            this.$route.params.id +
            "/afe/" +
            this.pwcaid +
            "/cctwo/" +
            cc2
        )
        .then((response) => {
          if (code === "") {
            cc3 = "";
            return cc3;
          }

          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length === 0) {
            return this.createCC3(cc2, code).then((data) => {
              cc3 = data.id;
              return cc3;
            });
          }
          if (match.length > 0) {
            cc3 = match[0].ccthreeid;
            return cc3;
          }
        });
    },
    createCC3(cctwoid, code) {
      let data = JSON.stringify({
        cctwoid: cctwoid,
        projectid: this.$route.params.id,
        code: code,
      });

      return this.$axios.post("/wts/create/cc3/", data).then((response) => {
        return response.data;
      });
    },
    upload(e) {
      const that = this;
      const fileToLoad = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (fileLoadedEvent) => {
        Papa.parse(fileLoadedEvent.target.result, {
          header: true,
          complete(results) {
            var temp = [];

            if (!this.is_resource) {
              temp = results.data.filter((x) => x.CostCode1 !== "");
            } else {
              temp = results.data.filter((x) => x.Major !== "");
            }

            that.doc = temp.map((obj) => {
              obj.Active = "false";
              return obj;
            });
          },
          error(errors) {},
        });
      };
      reader.readAsText(fileToLoad);
    },
    loadVendorServices(vid) {
      return this.$axios
        .get("/wts/get/vas/" + this.$route.params.id)
        .then((response) => {
          var test = response.data.result;
          var list = (this.wtsVendorServiceData = test.filter(
            (x) => x.vid === vid
          ));
          return list;
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
    loadData() {
      this.$axios
        .get("/wts/get/vendors/" + this.$route.params.id)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.result;
            this.wtsVendorList = data;
          }
        })
        .catch((error) => {});
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.fa-exclamation-triangle {
  color: #ffc107;
  margin-right: 0.5em;
}

#importChain {
  .table-responsive {
    overflow-x: hidden !important;
  }
  .done {
    background: red;
  }

  td:first-child,
  th:first-child {
    width: inherit !important;
  }

  .modal-dialog {
    width: 85%;
    max-width: 85%;
    float: right;
    margin-right: 2em;
  }
}
</style>
